<template>
    <modal ref="modalCrearLanzamiento" :titulo="`${editando ? 'Editar' : 'Crear'} Lanzamiento`" no-aceptar adicional="Guardar" @adicional="handleSave()">
        <div class="row mx-0 justify-center">
            <div class="col-9">
                <label class="text-general f-14 pl-3">Nombre del lanzamiento</label>
                <el-input v-model="nombre" size="small" class="w-100" placeholder="Nombre" show-word-limit maxlength="60" />
            </div>
            <div class="col-9 mt-3">
                <p class="text-general f-15 pl-3 f-600">Tipo de envio</p>
                <div class="row mx-0 mt-3">
                    <el-checkbox v-model="push">
                        Notificación push
                    </el-checkbox>
                    <el-checkbox v-model="mensaje">
                        Mensajes de texto
                    </el-checkbox>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
export default {
    data(){
        return {
            nombre: '',
            push: true,
            mensaje: true,
            editando: false,
        }
    },
    computed: {
        idCampana(){
            return this.$route.params.id_campana
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearLanzamiento.toggle();
        },
        editar(model){
            this.editando = true
            let newModel = _.cloneDeep(model)
            this.nombre = newModel.nombre
            this.mensaje = !!newModel.mensaje
            this.push = !!newModel.push
            this.$refs.modalCrearLanzamiento.toggle();
        },
        handleSave(){
            if (this.editando){
                this.editarLanzamiento()
            } else {
                this.guardarLanzamiento()
            }
        },
        async editarLanzamiento(){
            try {
                if(!this.push && !this.mensaje) return this.notificacion('', 'Debe seleccionar al menos una opción', 'error')
                if(!this.nombre) return this.notificacion('', 'El nombre es requerido', 'error')
                let model = {
                    nombre: this.nombre,
                    mensaje: +this.mensaje,
                    push: +this.push,
                }
                const { data } = await notificaciones.editarLanzamiento(model, this.$route.params.id_lanzamiento);
                if(data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')
                    this.$refs.modalCrearLanzamiento.toggle();
                    this.$emit('saved')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async guardarLanzamiento(){
            try {
                if(!this.push && !this.mensaje) return this.notificacion('', 'Debe seleccionar al menos una opción', 'error')
                if(!this.nombre) return this.notificacion('', 'El nombre es requerido', 'error')
                let model = {
                    nombre: this.nombre,
                    mensaje: +this.mensaje,
                    push: +this.push,
                    campana: this.idCampana,
                }
                const { data } = await notificaciones.crearLanzamiento(model);
                if(data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.$refs.modalCrearLanzamiento.toggle();
                    this.$emit('saved')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.nombre = ''
            this.push = true
            this.mensaje = true
            this.editando = false
        }
    }
}
</script>